"use client";
import React, { useCallback } from "react";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import Image from "next/image";
import Link from "next/link";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";

export const CarouselSignature = () => {
  const [emblaRef, emblaApi] = useEmblaCarousel(
    { loop: true, slidesToScroll: 1 },
    [Autoplay()]
  );

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  return (
    <div className="flex">
      <button className="embla__prev pr-4" onClick={scrollPrev}>
        <MdArrowBackIos size={30} />
      </button>
      <div className="embla" ref={emblaRef}>
        <div className="embla__container ">
          <div className="embla__slide">
            <section>
              <div className=" text-white text-left">
                <div className="max-w-screen-xl mx-5 lg:mx-auto">
                  <img
                    width={400}
                    height={400}
                    src="/images/ride_design.webp"
                    className="object-cover rounded-2xl border-8 border-white w-full"
                    loading="lazy"
                  />
                </div>
              </div>
            </section>
          </div>
          <div className="embla__slide">
            <section>
              <div className=" text-white text-left">
                <div className="max-w-screen-xl mx-5 lg:mx-auto">
                  <img
                    width={400}
                    height={400}
                    src="/images/ride_design-2.webp"
                    className="object-cover rounded-2xl border-8 border-white w-full"
                  />
                </div>
              </div>
            </section>
          </div>
          <div className="embla__slide">
            <section>
              <div className=" text-white text-left">
                <div className="max-w-screen-xl mx-5 lg:mx-auto">
                  <img
                    width={400}
                    height={400}
                    src="/images/ride_design-3.webp"
                    className="object-cover rounded-2xl border-8 border-white w-full"
                  />
                </div>
              </div>
            </section>
          </div>
          <div className="embla__slide">
            <section>
              <div className=" text-white text-left">
                <div className="max-w-screen-xl mx-5 lg:mx-auto">
                  <img
                    width={400}
                    height={400}
                    src="/images/Pirate-Island-Dolphin.webp"
                    className="object-cover rounded-2xl border-8 border-white w-full"
                  />
                </div>
              </div>
            </section>
          </div>
          <div className="embla__slide">
            <section>
              <div className=" text-white text-left">
                <div className="max-w-screen-xl mx-5 lg:mx-auto">
                  <img
                    width={400}
                    height={400}
                    src="/images/pg 9-10-Nethelands down.webp"
                    className="object-cover rounded-2xl border-8 border-white w-full"
                  />
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <button className="embla__next pl-4" onClick={scrollNext}>
        <MdArrowForwardIos size={30} />
      </button>
    </div>
  );
};

export default CarouselSignature;
