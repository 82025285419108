"use client";
import React, { useCallback } from "react";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import Image from "next/image";
import Link from "next/link";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { isMobile } from "react-device-detect";

export const EmblaCarousel = () => {
  const [emblaRef, emblaApi] = useEmblaCarousel(
    { loop: true, slidesToScroll: isMobile ? 1 : 2 },
    [Autoplay()]
  );

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  return (
    <div className="flex">
      <button className="embla__prev pr-4" onClick={scrollPrev}>
        <MdArrowBackIos size={30} />
      </button>
      <div className="embla px-0 lg:px-10" ref={emblaRef}>
        <div className="embla__container1 flex gap-10">
          <div className="embla__slide1 flex-[0_0_100%] lg:flex-[0_0_50%]">
            <div className="border border-blue-500 rounded-2xl">
              <p className="mb-0">
                <img
                  width={400}
                  height={400}
                  src="/images/Infrastructure.webp"
                  className="object-cover rounded-t-2xl w-full"
                />
              </p>
              <div className="p-6 md:p-8">
                <h4 className="text-black font-semibold uppercase">
                  Infrastructure
                </h4>
                <p className="pb-24">
                  We are present in 2 locations with our corporate office and
                  manufacturing plant in Mumbai. Our total manufacturing plant
                  sizes are close to 75000 Sq feet thereby greater flexibility
                  to produce and supply to large water etc. We use AutoCAD 3d
                  and modern design software to design our initial layout to
                  final design and simulation. We have a team engineers
                  dedicated to design department. We have more than 300 slides
                  thereby giving us larger flexibility while designing slides
                  with different slopes which in effect delivers higher guest
                  experience while sliding on slides manufactured by APARC.
                </p>
              </div>
            </div>
          </div>
          <div className="embla__slide1 flex-[0_0_100%] lg:flex-[0_0_50%]">
            <div className="border border-blue-500 rounded-2xl">
              <p className="mb-0">
                <img
                  width={400}
                  height={400}
                  src="/images/app.webp"
                  className="object-cover rounded-t-2xl w-full"
                />
              </p>
              <div className="p-6 md:p-8">
                <h4 className="text-black font-semibold uppercase">
                  Design & Engineering
                </h4>
                <p>
                  At APARC, we aim to deliver consistent quality, customised
                  Product and cost effective Solutions to water parks. We aim to
                  achieve best capacity utilisation and high standards of guest
                  experience. With precise design, we strive to eliminate the
                  chance of trial & error on site which in turn maximises fun &
                  thrill of the rides within safe design Parameters.
                </p>
                <ul className="text-left pl-10 list-disc pb-10">
                  <li>Master Planning</li>
                  <li>Selection</li>
                  <li>Initial Layout Design</li>
                  <li>Structural Design for Steel Supports</li>
                  <li>Schematic Foundations & Plumbing Designs</li>
                  <li>Theming Sketch for Attractions</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="embla__slide1 flex-[0_0_100%] lg:flex-[0_0_50%]">
            <div className="border border-blue-500 rounded-2xl">
              <p className="mb-0">
                <img
                  width={400}
                  height={400}
                  src="/images/qulati-stander.webp"
                  className="object-cover rounded-t-2xl w-full"
                />
              </p>
              <div className="p-6 md:p-8">
                <h4 className="text-black font-semibold">
                  QUALITY STANDARDS & MANUFACTURING PROCESS
                </h4>
                <p>
                  APARC is an ISO 9001-2015 certified company and all slides and
                  equipment at APARC confirms to the widely recognised EN 1069
                  (European Norms) ASTM(American Standards)as well as BIS
                  standards (lndian Standards). Our team of experts capability
                  to confirm the designs and product as per TUV Standards as
                  well. From Sketch to Reality we ensure with our team of
                  qualified & experienced engineers adhere to strict quality
                  standards. We use best quality raw materials and advanced LRTM
                  technology for production of our water slides. We adhere to
                  highest quality checks right from raw material stage till the
                  dispatch of goods to achieve zero defect products. Our focus
                  is to deliver consistently high-quality products which can
                  suffice international quality standards round the globe.
                </p>
              </div>
            </div>
          </div>
          <div className="embla__slide1 flex-[0_0_100%] lg:flex-[0_0_50%]">
            <section>
              <div className="border border-blue-500 rounded-2xl">
                <p className="mb-0">
                  <img
                    width={400}
                    height={400}
                    src="/images/Installation_11zon.webp"
                    className="object-cover rounded-t-2xl w-full"
                  />
                </p>
                <div className="p-6 md:p-8 ">
                  <h4 className="text-black font-semibold">
                    INSTALLATION & AFTER SALES SERVICE
                  </h4>
                  <p className="pb-36">
                    At APARC, we have experienced team of installation experts
                    who have been involved in installing water parks in several
                    countries worldwide. Our Installation support team ensures
                    that the slides and equipment are installed with highest
                    safety standards. We have a specialised after sales team
                    which can assist clients in enhancing the life span of the
                    water park equipment installed in the park
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <button className="embla__next pl-4" onClick={scrollNext}>
        <MdArrowForwardIos size={30} />
      </button>
    </div>
  );
};

export default EmblaCarousel;
