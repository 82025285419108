"use client";
import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";

const schema = z.object({
  name: z.string().min(1, { message: "Name is required" }),
  email: z
    .string()
    .email({ message: "Invalid email address" })
    .min(1, { message: "Email is required" }),
  phone: z.string().min(1, { message: "Phone is required" }),
  message: z.string().min(1, { message: "Message is required" }),
});

export default function FormContactUs() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const [isSending, setIsSending] = useState(false);
  const [message, setMessage] = useState("");

  const onSubmit = async (data) => {
    setIsSending(true); // Start sending
    setMessage(""); // Reset message state

    try {
      const response = await fetch("/api/sendMail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        setMessage("Email sent successfully. Check your inbox.");
        reset();
      } else {
        throw new Error("Failed to send email");
      }
    } catch (error) {
      setMessage(error.message);
    } finally {
      setIsSending(false); // Done sending
    }
  };

  const handleNewForm = () => {
    reset(); // Reset form fields
    setMessage(""); // Clear the message
  };

  if (isSending) {
    return (
      <div className="bg-gray-200 flex flex-col items-center justify-center border rounded-xl p-4 sm:p-6 lg:p-10">
        <p>Sending...</p>
      </div>
    );
  }

  if (message) {
    return (
      <div className="bg-gray-200 flex flex-col items-center justify-center border rounded-xl p-4 sm:p-6 lg:p-10">
        <h3 className="text-lg font-semibold uppercase">Thank You!</h3>
        <p>{message}</p>
        <button
          onClick={handleNewForm}
          className="mt-4 py-2 px-4 bg-blue-500 text-white rounded-lg shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Submit another response
        </button>
      </div>
    );
  }

  // Render the form
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mt-6 grid gap-4 lg:gap-6">
        <input
          {...register("name", { required: true })}
          className="py-3 px-4 block w-full border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500"
          placeholder="Enter Name..."
        />
        {errors.name && <p>{errors.name.message}</p>}

        <input
          {...register("email", { required: true })}
          className="py-3 px-4 block w-full border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500"
          placeholder="Enter Email..."
        />
        {errors.email && <p>{errors.email.message}</p>}

        <input
          {...register("phone", { required: true })}
          className="py-3 px-4 block w-full border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500"
          placeholder="Enter Phone..."
        />
        {errors.phone && <p>{errors.phone.message}</p>}

        <textarea
          {...register("message", { required: true })}
          className="py-3 px-4 block w-full border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500"
          placeholder="Enter Message"
        ></textarea>
        {errors.message && <p>{errors.message.message}</p>}
      </div>
      <div className="mt-6">
        <button
          type="submit"
          className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700"
        >
          Submit
        </button>
      </div>
    </form>
  );
}
